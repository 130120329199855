import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import small1 from "../images/small-1.jpg"
import small2 from "../images/small-2.jpg"
import Hero from "../components/hero";
import SocialMedia from "../components/social-media";
import {Helmet} from "react-helmet";
import schedule from "../images/adult-sched.png";
import example from "../images/adultclassexample.jpg";
import OpenGraphLogo from "../images/open-graph-logo.png"


function AdultGroupClass(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | Adult Group Class</title>
                <meta property="og:image" content={OpenGraphLogo}></meta>
            </Helmet>
            <Nav/>
            <Hero href={"/contact"} header={"Adult Group Class - Let’s become healthier and level up TOGETHER!"}>
                <div id={"content-start"} className={"flex flex-col justify center max-w-5xl mx-auto px-8 pt-8 text-lg bg-white rounded-xl"}>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>We offer larger group training programs that make long term health and durability a priority. Feeling good, having more energy, being able to play with friends and family are all a byproduct! </p>
                    <iframe className={"py-5 mx-auto w-full lg:w-auto aspect-video"} width="560" height="315" src="https://www.youtube.com/embed/cLzcTe_73ns"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    <div className={"lg:flex lg:flex-row lg:justify-center "}>
                        <div className={"lg:flex lg:flex-row lg:justify-center "}>
                            <a className={"lg:px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started! </button></a>
                            <a className={"lg:px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                        </div>
                    </div>
                    <h2 className={"text-3xl text-center py-14"}>Schedule (subject to change)</h2>
                    <img src={schedule} alt={'schedule'}/>



                    <h2 className={"text-3xl text-center py-14"}>Are you looking to build your office morale?</h2>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>Large group training is a phenomenal outlet for corporate wellness. The best ROI for business owners is healthy, positive and motivated employees. Large group training is a great way to build morale, decrease sick days and provide a positive bonding experience for your staff. When you value your health, so do your employees and productivity will reflect it. This is sweat equity. </p>
                    <iframe className={"py-5 mx-auto w-full lg:w-auto aspect-video"} width="560" height="315" src="https://www.youtube.com/embed/vd-GAUj5ASU"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    <div className={"lg:flex lg:flex-row lg:justify-center "}>
                        <div className={"lg:flex lg:flex-row lg:justify-center "}>
                            <a className={"lg:px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started! </button></a>
                            <a className={"lg:px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                        </div>
                    </div>




                    <h2 className={"text-3xl text-center py-14"}>Parents, it is time to decompress and take some time for yourself. </h2>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>Sweat together with a group of your friends so you can get out of the house and take a breather from the kids. Make social hour a fitness hour so you can release some stress of being an uber driver. Learn how to become healthier and give some well-earned time to yourself.</p>
                    <img src={example} alt={'example'} className={"mx-auto lg:w-2/3"}/>

                    <div className={"lg:flex lg:flex-row lg:justify-center pb-24"}>
                        <a className={"lg:px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started! </button></a>
                        <a className={"lg:px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                    </div>



                </div>
            </Hero>



            <Footer/>
        </div>
    )
}

export default AdultGroupClass